<template>
  <div class="register-page register-sign-up-page">
    <breadCrumbs :current="2" :total="6"></breadCrumbs>
    <div class="container-wide">
      <img src="/static/img/logo-soco.svg" class="logo-soco" alt="register-cover-image" />
      <div class="welcome-text">
        <!-- <img src="/static/img/login-mobile.png" alt> -->
        <h3 v-if="registerData.first_name" class="register-subtile">
          Hi {{ registerData.first_name }}, yuk lengkapi profilmu!
        </h3>

        <h3 v-else class="register-subtile">Yuk lengkapi profilmu!</h3>
      </div>

      <form
        id="form-register"
        method="post"
        name="form-register"
        data-vv-scope="form-register"
        @submit.prevent="submitRegisterProfile('form-register')"
      >
        <div class="checkbox-radio-wrap">
          <label class="checkbox-container">
            Female
            <input
              v-model="registerData.salute"
              v-validate="'required'"
              type="radio"
              checked="checked"
              value="Ms"
              name="radio"
            />
            <span class="checkmark"></span>
          </label>
          <label class="checkbox-container">
            Male
            <input v-model="registerData.salute" v-validate="'required'" type="radio" value="Mr" name="radio" />
            <span class="checkmark"></span>
          </label>
        </div>

        <div class="form-inline-wrap">
          <div class="input-box" :class="{ 'has-error': errors.has('form-register.firstname') }">
            <div class="fieldarea">
              <input
                v-model="registerData.date_of_birth"
                v-validate="'required'"
                type="text"
                class="hidden"
                name="tanggal"
              />
              <div id="tanggal" class="fieldarea">
                <datepicker
                  v-model="registerData.date_of_birth"
                  placeholder="Tanggal Lahir"
                  :disabled-dates="{ from: new Date(minAge.year, minAge.month, minAge.date) }"
                  :open-date="minimumDate"
                  @selected="convertDate"
                ></datepicker>
                <!-- <span class="triangle" v-if="heycarret"></span> -->
                <p v-show="errors.has('form-register.tanggal')" class="alert">{{ errors.first('tanggal') }}</p>
              </div>
            </div>
          </div>
          <div class="input-box" :class="{ 'has-error': errors.has('form-register.lastname') }">
            <div class="fieldarea">
              <input
                id="hp"
                v-model="registerData.phone_no"
                v-validate="'required|numeric|min:10'"
                type="text"
                class="text default"
                placeholder="Nomor HP"
                name="hp"
                autocomplete="off"
              />
              <p v-show="errors.has('form-register.hp')" class="alert">{{ errors.first('form-register.hp') }}</p>
            </div>
          </div>
        </div>

        <p class="form-info">Negara: Indonesia</p>

        <div class="form-inline-wrap">
          <div class="input-box" :class="{ 'has-error': errors.has('form-register.firstname') }">
            <!-- <div class="fieldarea select">
              <select
                name="province"
                id="province"
                v-model="registerData.province_id"
                v-validate="'required'"
                @change="loadCity"
              >
                <option
                  value
                  selected
                  disabled
                  v-if="!registerData.selectCountry"
                >Pilih negara terlebih dahulu</option>
                <option value selected disabled v-else>Pilih provinsi</option>
                <option
                  v-for="provinsi in registerData.province"
                  :value="provinsi._id"
                  :key="provinsi._id"
                >{{ provinsi.name }}</option>
              </select>
              <p v-show="errors.has('province')" class="alert">{{ errors.first('province') }}</p>
            </div>-->
            <div class="input-row">
              <div class="input-box">
                <input
                  v-if="registerData.selectCountry"
                  v-model="provinsiLabel"
                  type="text"
                  class="default push-icon to-right dropdown"
                  name
                  placeholder="Pilih provinsi"
                  @click="toggleProvinsi()"
                  @change="loadCity"
                />
                <input
                  v-else
                  class="default push-icon to-right dropdown"
                  disabled
                  name
                  placeholder="Pilih negara dulu"
                />
                <span class="icon dropdown"></span>
                <template v-if="showProvinsi">
                  <span class="triangle"></span>
                  <ul class="dropdown--selectbox">
                    <li
                      v-for="provinsi in registerData.province"
                      :key="provinsi._id"
                      :value="provinsi._id"
                      @click="selectProvinsi(provinsi)"
                    >
                      {{ provinsi.name }}
                    </li>
                  </ul>
                </template>
              </div>
            </div>
          </div>

          <div class="input-box" :class="{ 'has-error': errors.has('form-register.firstname') }">
            <!-- <div class="fieldarea select">
              <select
                name="city"
                id="city"
                :disabled="registerData.selectProvince ? false : true"
                v-model="registerData.city_id"
                v-validate="'required'"
              >
                <option
                  value
                  selected
                  disabled
                  v-if="!registerData.selectProvince"
                >Pilih provinsi dulu</option>
                <option value selected disabled v-else>Pilih kota / kabupaten</option>
                <option
                  v-for="kota in registerData.city"
                  :value="kota._id"
                  :key="kota._id"
                >{{ kota.name }}</option>
              </select>
              <p v-show="errors.has('city')" class="alert">{{ errors.first('city') }}</p>
            </div>-->
            <div class="input-row">
              <div class="input-box">
                <input
                  v-if="registerData.province_id"
                  v-model="cityLabel"
                  type="text"
                  class="default push-icon to-right dropdown"
                  name
                  placeholder="Pilih provinsi"
                  @click="toggleCity()"
                />
                <input
                  v-else
                  class="default push-icon to-right dropdown"
                  type="text"
                  disabled
                  name
                  placeholder="Pilih provinsi dulu"
                />
                <span class="icon dropdown"></span>
                <template v-if="showCity">
                  <span class="triangle"></span>
                  <ul class="dropdown--selectbox">
                    <li v-for="city in registerData.city" :key="city._id" :value="city._id" @click="selectCity(city)">
                      {{ city.name }}
                    </li>
                  </ul>
                </template>
              </div>
            </div>
          </div>
        </div>

        <div class="fixed-bottom">
          <!-- <router-link
            to="/register/account"
            class="btn half-full"
            v-if="showLoader == false"
            type="submit"
          >
            <i class="arrow_carrot-left_alt"></i>
            BACK
          </router-link> -->
          <button
            v-if="showLoader == false"
            class="btn btn-full submit"
            type="submit"
            :disabled="errors.any() || !isDirty"
          >
            NEXT
            <i class="arrow_carrot-right_alt"></i>
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import breadCrumbs from './bread-crumbs';
import Datepicker from 'vuejs-datepicker';

export default {
  name: 'regProfile',
  components: {
    breadCrumbs,
    Datepicker,
  },
  data() {
    return {
      heycarret: false,
      showLoader: false,
      minAge: {
        year: '',
        month: '',
        date: '',
        fullDate: '',
      },
      registerData: {
        user_name: '',
        name: '',
        email: '',
        salute: 'Ms',
        first_name: '',
        last_name: '',
        date_of_birth: '',
        birthday_date: '',
        birthday_month: '',
        birthday_year: '',
        phone_no: '',
        country_id: 999,
        selectCountry: true,
        province: [],
        province_id: '',
        selectProvince: false,
        city: [],
        city_id: '',
        district: [],
        district_id: '',
        is_guest: 0,
        client_id: 0,
      },
      showProvinsi: false,
      provinsiLabel: '',
      showCity: false,
      cityLabel: '',
    };
  },
  computed: {
    isDirty() {
      return (
        this.registerData.birthday_date &&
        this.registerData.phone_no &&
        this.registerData.province_id &&
        this.registerData.city_id
      );
    },
    minimumDate() {
      const date = new Date();
      date.setFullYear(date.getFullYear() - 13);
      return date;
    },
  },
  watch: {
    // registerData(newVal, oldVal) {
    //   if (newVal.date_of_birth !== oldVal.date_of_birth) {
    //     this.hidemagic();f
    //   }
    // }
  },
  mounted() {
    this.getProfile();
    this.loadProvince();
    this.minimumAge();
  },
  methods: {
    toggleProvinsi() {
      this.showProvinsi = !this.showProvinsi;
    },
    selectProvinsi(provinsi) {
      this.showProvinsi = false;
      this.registerData.province_id = provinsi._id;
      this.provinsiLabel = provinsi.name;
      this.cityLabel = '';
      this.loadCity();
    },
    toggleCity() {
      this.showCity = !this.showCity;
    },
    selectCity(city) {
      this.showCity = false;
      this.registerData.city_id = city._id;
      this.cityLabel = city.name;
    },
    loadCity() {
      this.registerData.selectProvince = false;
      this.registerData.city = [];
      this.registerData.city_id = '';
      this.registerData.selectCity = false;
      this.registerData.district = [];
      this.registerData.district_id = '';

      this.$MS_SOCO_PUBLIC_API_URL
        .get('cities?province_id=' + this.registerData.province_id)
        .then((response) => {
          this.registerData.city = response.data.data;
          this.registerData.selectProvince = true;
        })
        .catch((error) => {});
    },
    loadProvince() {
      this.registerData.city = [];
      this.registerData.city_id = '';
      this.registerData.district = [];
      this.registerData.district_id = '';

      this.$MS_SOCO_PUBLIC_API_URL
        .get('provinces')
        .then((response) => {
          this.registerData.province = response.data.data;
          this.registerData.selectCountry = true;
        })
        .catch((error) => {});
    },
    convertDate(data) {
      const d = new Date(data);
      this.registerData.birthday_date = d.getDate();
      this.registerData.birthday_month = d.getMonth() + 1;
      this.registerData.birthday_year = d.getFullYear();
    },
    minimumAge() {
      const date = new Date();
      date.setFullYear(date.getFullYear() - 13);
      this.minAge.year = date.getFullYear();
      this.minAge.month = date.getMonth();
      this.minAge.date = date.getDate();
      this.minAge.fullDate = date;
    },
    toggleRePassword() {
      if (this.revealRePassword === 'password') {
        this.revealRePassword = 'text';
      } else {
        this.revealRePassword = 'password';
      }
    },
    togglePassword() {
      if (this.revealPassword === 'password') {
        this.revealPassword = 'text';
      } else {
        this.revealPassword = 'password';
      }
    },
    submitRegisterProfile() {
      const formData = {
        salute: this.registerData.salute,
        phone_no: this.registerData.phone_no,
        date_of_birth: this.registerData.date_of_birth,
        city: { id: this.registerData.city_id, name: this.cityLabel },
        province: { id: this.registerData.province_id, name: this.provinsiLabel },
        country_id: 999,
        country: { name: 'Indonesia' },
        is_guest: 0,
        client_id: 0,
        user_name: this.registerData.user_name,
        email: this.registerData.email,
        first_name: this.registerData.first_name,
        last_name: this.registerData.last_name,
        name: this.registerData.name,
        image: this.registerData.image,
      };
      this.$MS_SOCO_PUBLIC_API_URL
        .post(`/auth/register/update`, formData)
        .then((res) => {
          if (res.data.success) {
            // console.log(res.data.data);
            this.$toasted.global.show('Berhasil mendaftarkan profile anda');
            this.$router.push('/register/beauty');
          } else if (!res.data.success && res.data.message) {
            this.$toasted.global.error(this.hummanizeMessage(res.data.message));
          } else {
            this.$toasted.global.error('Gagal mendaftarkan profile anda');
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
    getProfile() {
      if (!this.$store.state.auth.userProfile) {
        this.$MS_SOCO_PUBLIC_API_URL
          .get(`/user/me`)
          .then((res) => {
            if (res.data.success == true) {
              const data = res.data.data;
              this.$store.dispatch('setProfile', data);
              return data;
            } else {
              this.$router.push('/register');
            }
          })
          .then((data) => {
            // console.log(data)
            this.registerData.user_name = data.user_name;
            this.registerData.email = data.email;
            this.registerData.first_name = data.first_name;
            this.registerData.last_name = data.last_name;
            this.registerData.name = data.first_name + ' ' + data.last_name;
            this.registerData.image = data.image;
          });
      } else {
        this.registerData.user_name = this.$store.state.auth.userProfile.user_name;
        this.registerData.email = this.$store.state.auth.userProfile.email;
        this.registerData.first_name = this.$store.state.auth.userProfile.first_name;
        this.registerData.last_name = this.$store.state.auth.userProfile.last_name;
        this.registerData.name =
          this.$store.state.auth.userProfile.first_name + ' ' + this.$store.state.auth.userProfile.last_name;
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
